<template>
  <div class="content-wrap">
    <Header />
    <div class="main">
      <div class="switch">
        <div class="action-box">
          <label for="">填报功能</label>
          <Switch v-model="valve" @on-change="change" />
        </div>
        <p>{{ msg }}</p>
      </div>
    </div>
    <Footer />
  </div>
</template>
      
   <script setup>
import { ref, watch, inject, onMounted } from "vue";
import Header from "./components/setupHeader.vue";
import Footer from "@/components/footer.vue";
let axios = inject("axios");
let valve = ref(true);
let msg = ref("已开启");
watch(
  () => valve.value,
  (val) => {
    if (val) {
      msg.value = "已开启";
    } else {
      msg.value = "已关闭";
    }
  }
);
//切换状态
const change = (val) => {
  let url = `/setting/fillState`;
  axios.get(url).then((res) => {
    if (res.data.code == 200) {
      getStatusInfo();
    } else {
      getStatusInfo();
    }
  });
};

//获取系统是否开启信息
const getStatusInfo = () => {
  let url = `/setting/getFillState`;
  axios.get(url).then((res) => {
    if (res.data.code == 200) {
      let data = res.data.data;
      valve.value = data.state;
    } else {
      valve.value = false;
    }
  });
};
onMounted(() => {
  getStatusInfo();
});
</script>
      
  <style lang="scss" scoped>
.main {
  height: calc(100vh - 110px);

  .switch {
    padding: 30px 0;
    border-top: 1px solid #888888;
    border-bottom: 1px solid #888888;
    margin-top: 30px;
    .action-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      label {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 20px;
        letter-spacing: 0px;
        color: #555555;
        line-height: 30px;
      }
      :deep(.ivu-switch-checked) {
        border-color: #688aea;
        background-color: #688aea;
      }
    }
    > p {
      font-size: 18px;
      color: #111111;
      font-weight: normal;
      font-stretch: normal;
    }
  }
}
</style>